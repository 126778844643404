export const pages = {
    "home": {
        title: "The Real Lifestyle | Global Real Estate Investment Through Tokenization",
        meta: {
            description:
                "Explore TRL’s innovative platform for tokenized real estate investments, making global property ownership accessible, secure, and liquid for everyone.",
            keywords: "real estate tokenization, global property investment, fractional ownership, blockchain",
        }
    },
    "roadmap": {
        title: "The Real Lifestyle | Discover TRL's Roadmap",
        meta: {
            description:
                "Explore TRL’s roadmap to transform global real estate investment. Follow our journey in tokenization, platform features, and new opportunities.",
            keywords: "TRL roadmap, real estate tokenization, project milestones, investment timeline",
        }
    },
    "ecosystem": {
        title: "The Real Lifestyle | Discover TRL's Partnership Ecosystem",
        meta: {
            description:
                "Discover TRL’s ecosystem, providing end-to-end solutions for tokenized real estate, including partnerships, trading, and global investment access.",
            keywords: "TRL ecosystem, tokenized real estate, global investment, blockchain",
        }
    },
    "blog": {
        title: "The Real Lifestyle | Insights on Real Estate Tokenization & Blockchain",
        meta: {
            description:
                "Stay updated with TRL's blog for the latest insights on real estate tokenization, blockchain trends, and investment opportunities worldwide.",
            keywords: "TRL blog, real estate tokenization, blockchain insights, investment trends",
        }
    },
    "how-to-buy": {
        title: "The Real Lifestyle | Learn How to Buy Tokenized Real Estate",
        meta: {
            description:
                "Learn how to invest in tokenized real estate with TRL. Follow our step-by-step guide to purchase and own fractional property assets globally.",
            keywords: "how to buy tokenized real estate, TRL investment guide, fractional property ownership, real estate tokens",
        }
    },
    "faq": {
        title: "The Real Lifestyle | Real Estate Tokenization & Investment FAQ",
        meta: {
            description:
                "Find answers to common questions about TRL’s tokenized real estate platform, investment process, ownership, and blockchain technology.",
            keywords: "TRL FAQ, real estate tokenization, blockchain investment, property ownership",
        }
    },
    "about": {
        title: "The Real Lifestyle | Revolutionizing Real Estate with Blockchain",
        meta: {
            description:
                "Learn about TRL, a leader in real estate tokenization, transforming global property investment with secure, transparent, and accessible blockchain solutions.",
            keywords: "about TRL, real estate tokenization, blockchain solutions, property investment",
        }
    },
    "media": {
        title: "The Real Lifestyle | Latest News & Press on Real Estate Tokenization",
        meta: {
            description:
                "Explore the latest media coverage, press releases, and updates about TRL’s innovative approach to real estate tokenization and blockchain solutions.",
            keywords: "TRL media, real estate tokenization news, blockchain press, TRL updates",
        }
    },
    "contact": {
        title: "The Real Lifestyle | Contact",
        meta: {
            description:
                "Reach out to TRL for inquiries about real estate tokenization, investment opportunities, or partnership discussions. We're here to assist you.",
            keywords: "contact TRL, real estate tokenization inquiries, TRL support, customer care",
        }
    },
};
